<template>
  <VaLayout
    :top="{ fixed: true, order: 2 }"
    :left="{ fixed: true, absolute: breakpoints.mdDown, order: 1, overlay: breakpoints.mdDown && !isSidebarMinimized }"
    @leftOverlayClick="isSidebarMinimized = true"
  >
    <template #top>
      <AppNavbar :is-mobile="isMobile" />
    </template>

    <template #left>
      <AppSidebar :minimized="isSidebarMinimized" :animated="!isMobile" :mobile="isMobile" :role="store.role" />
    </template>

    <template #content>
      <div :class="{ minimized: isSidebarMinimized }" class="app-layout__sidebar-wrapper">
        <div v-if="isFullScreenSidebar" class="flex justify-end">
          <VaButton class="px-4 py-4" icon="md_close" preset="plain" @click="onCloseSidebarButtonClick" />
        </div>
      </div>
      <AppLayoutNavigation v-if="!isMobile" class="p-4" />
      <main class="p-4 pt-0">
        <article>
          <RouterView />
        </article>
      </main>
    </template>
  </VaLayout>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useRouter, onBeforeRouteUpdate } from 'vue-router'
import { useBreakpoint } from 'vuestic-ui'

import { useGlobalStore } from '../stores/global-store'

import AppLayoutNavigation from '../components/app-layout-navigation/AppLayoutNavigation.vue'
import AppNavbar from '../components/navbar/AppNavbar.vue'
import AppSidebar from '../components/sidebar/AppSidebar.vue'
import userAPI from '../api/user';
import { useUserStore } from '../stores/user-store'

const store = useUserStore()
import { useColors } from 'vuestic-ui'

const { push } = useRouter()
const { applyPreset, currentPresetName } = useColors()

const GlobalStore = useGlobalStore()

const breakpoints = useBreakpoint()

const sidebarWidth = ref('16rem')
const sidebarMinimizedWidth = ref(undefined)

const isMobile = ref(false)
const isTablet = ref(false)
const { isSidebarMinimized } = storeToRefs(GlobalStore)

const onResize = () => {
  isSidebarMinimized.value = breakpoints.mdDown
  isMobile.value = breakpoints.smDown
  isTablet.value = breakpoints.mdDown
  sidebarMinimizedWidth.value = isMobile.value ? '0' : '4.5rem'
  sidebarWidth.value = isTablet.value ? '100%' : '16rem'
}

const theme = computed({
  get() {
    return currentPresetName.value
  },
  set(value) {
    applyPreset(value)
  },
})

onMounted(() => {
  // theme.value = 'dark';
  let token = sessionStorage.getItem("KnToken") || localStorage.getItem("KnToken");
  if (!token) {
  push({ name: "login" });
} else {
  let currentStaff = sessionStorage.getItem("KnUser") || localStorage.getItem("KnUser");
  let currentStaffRole = sessionStorage.getItem("KnUserRole") || localStorage.getItem("KnUserRole");
  if (currentStaff) {
    userAPI.getUsersByID((res) => {
      if (res && res.success) {
        store.updateUser(res.data.user);
        push({ name: store.role == 3 ? 'merchants' : 'transactions' })
      }
    }, parseInt(currentStaff));
  }
  push({ name: !currentStaffRole || currentStaffRole == '3' ? 'merchants' : 'transactions' })
}
  window.addEventListener('resize', onResize)
  onResize()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})

onBeforeRouteUpdate(() => {
  if (breakpoints.mdDown) {
    // Collapse sidebar after route change for Mobile
    isSidebarMinimized.value = true
  }
})

const isFullScreenSidebar = computed(() => isTablet.value && !isSidebarMinimized.value)

const onCloseSidebarButtonClick = () => {
  isSidebarMinimized.value = true
}
</script>

<style lang="scss">
// Prevent icon jump on animation
$mobileBreakPointPX: 767px;
$tabletBreakPointPX: 1179px;
html::-webkit-scrollbar {
  display: none;
}

@media (min-width: 1024px) {
  .page-header .page-title {
    font-size: 2.25em
  }  
}
.va-sidebar {
  width: unset !important;
  min-width: unset !important;
}
.va-icon {
  aspect-ratio: 1;
}
@media (min-width: 640px) {
  .sm\:w-50 {
    width: 47% !important;
    margin: 0.5%;
  }
}
@media (min-width: 1024px) {
  .md\:w-50 {
    width: 47% !important;
    margin: 0.25% 1%;
  }

  .md\:w-30 {
    width: 30% !important;
    margin: 0.25% 1%;
  }

  .md\:w-25 {
    width: 21.75% !important;
    margin: 0.25% 1%;
  }

  .md\:w-20 {
    width: 18.75% !important;
    margin: 0.25% 1%;
  }
  .md\:w-10 {
    width: 8.75% !important;
    margin: 0.25% 1%;
  }
}
.va-action {
  margin-right: 3%;
  margin-top: 1%;
}

.filter-block {
  .va-collapse__header-wrapper {
    position: absolute;
    z-index: -1;
    opacity: 0;
    font-size: 16px;
  }
  .va-collapse__content {
    padding: 1.25rem 0 1.5rem;
  }
  .filter-form {
    padding: 0.5rem 0;
    .filter-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      .filter-item {
        width: calc(33.3% - 2rem);
        margin-bottom: 1rem;
        margin-right: 2rem;
        .va-input-label {
          font-size: 12px;
          text-transform: none !important;
          color: var(--va-text-primary) !important;
        }
      }
    }
    .actions {
      display: flex;
      padding-top: 1rem;
      justify-content: flex-end;
      align-items: stretch;
      .va-button {
        width: fit-content;
        .va-button__content {
          font-size: 14px;
          white-space: nowrap;
        }
      }
    }
  }
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .va-card__title {
    font-size: 16px;
  }
  .filter {
    .search-box {
      position: relative;
      .va-icon {
        font-size: 24px;
        height: 24px;
        line-height: 24px;
        display: block;
        position: absolute;
        top: 5px;
        right: 23px;
        color: #0000009d;
        cursor: pointer;
      }
      .va-icon.ion-ios-close {
        font-size: 30px;
        height: 30px;
        line-height: 30px;
        top: 2px;
        right: 28px;
      }
      .search {
        width: 34px;
        height: 34px;
        padding: 0;
        font-size: 16px;
        line-height: 20px;
        border: 1px solid #0000004d;
        border-radius: 16px;
        margin-right: 1rem;
        transition: all 0.5s;
      }
      .search.expand {
        width: 256px;
        padding-left: 16px;
        padding-right: 32px;
      }
      .search::placeholder {
        transition: all 0.3s;
      }
      .search:not(.expand)::placeholder {
        opacity: 0 !important;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    a {
      display: block;
      margin-right: 1.25rem;
      color: var(--va-primary);
    }
    a:hover {
      color: #093183;
    }
    a.btn {
      padding: 0.5rem 2rem;
      background: var(--va-primary);
      border: 1px solid var(--va-primary);
      color: var(--va-text-primary);
      border-radius: 2rem;
      font-weight: 600;
      transition: all 0.3s;
    }
    a.btn:hover {
      color: var(--va-primary);
      background-color: var(--va-text-primary);
    }
    a.icon {
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #0000004d;
      color: #0000009d;
      font-size: 20px;
      border-radius: 100%;
    }
  }
}

.card-errors {
  padding: 0 0 2rem;
  ul {
    li {
      font-size: 14px;
      line-height: 1.33;
      color: #e42222;
      .va-icon {
        font-size: 16px;
        line-height: 18px;
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
}

.table-crud {
  --va-form-element-default-width: 0;
  .va-data-table__table {
    white-space: normal;
    line-height: 1.33;
  }
  .va-input {
    display: block;
  }

  &__slot {
    th {
      vertical-align: middle;
    }
  }

  .va-data-table__table .va-data-table__table-th {
    font-weight: 600;
    font-size: 16px;
    text-transform: none;
    cursor: pointer;
    white-space: nowrap;
    .va-icon:not(.va-checkbox__icon) {
      margin-left: 0.5rem;
    }
  }

  .va-data-table__table .va-data-table__table-tbody .no-data {
    padding: 1rem;
  }

  a.object-title {
    color: var(--va-info);
  }

  a.object-title:hover {
    color: var(--va-focus);
    text-decoration: underline;
  }

  .va-data-table__table-thead .va-data-table__table-tr th,
  .va-data-table__table-tbody .va-data-table__table-tr td {
    white-space: nowrap;
    padding: 0.75rem 1.75rem;
  }

  .va-data-table__table-tbody .va-data-table__table-tr td .break-spaces {
    white-space: normal;
  }

  .va-data-table__table-thead .va-data-table__table-tr th.va-data-table__table-cell-select, 
  .va-data-table__table-tbody .va-data-table__table-tr td.va-data-table__table-cell-select {
    padding: 0.75rem;
  }
  .va-chip__content {
    color: #f1f1f1;
    line-height: 1.5;
  }
}
.va-checkbox {
  .va-checkbox__square {
    border: 1px solid #adadad;
  }
  .va-checkbox__icon {
    color: #f1f1f1 !important;
  }
}

.va-checkbox--on-keyboard-focus.va-checkbox .va-checkbox__square {
  outline: none !important;
}

.va-modal {
  .va-modal__title {
    font-size: 20px;
    line-height: 1.625;
    margin: 0 0 24px;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--va-text-primary) !important;
  }
  .va-input-label,
  .va-modal__default-cancel-button {
    color: var(--va-text-primary) !important;
  }
  .va-file-upload {
    background-color: transparent !important;
    margin-top: 0;
    width: fit-content;
    display: flex;
    align-items: center;
    .va-button--normal .va-button__content {
      font-size: 14px;
      white-space: nowrap;
    }
    .va-file-upload-single-item__content {
      justify-content: space-between
    }
    .va-file-upload-list {
      display: inline-block;
      margin: 0 0 0 1rem;
      padding: 0;
      width: fit-content;
      .va-file-upload-single-item__name {
        margin-right: 1rem;
      }
    }
    .va-file-upload-single-item__button {
      color: var(--va-danger) !important;
    }
  }
  .va-checkbox {
    display: block;
    .va-checkbox__icon {
      color: #f1f1f1 !important;
    }
  }
  .status-title {
    margin-top: 1%;
    font-weight: 700;
  }
  .add-pos-item {
    border-bottom: 1px solid var(--va-input-wrapper-border-color);
  }
  .download-sample a {
    line-height: 1.5;
    color: var(--va-info);
    text-decoration: underline;
  }
}
.list-pos-by-contract .va-data-table:not(.va-data-table--virtual-scroller) {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 50vh;
  .va-data-table__table .va-data-table__table-td {
    font-size: 12px;
    .va-chip .va-chip__content {
      font-size: 12px;
      color: #f1f1f1;
      line-height: 1.5;
    }
  }
}
.list-pos-to-update {
  border: 1px solid var(--va-input-wrapper-border-color);
  border-radius: 6px;
  position: relative;
  .status-title {
    margin-top: 0;
  }
  .checklist {
    height: 240px;
    overflow-y: auto;
  }
}
.list-pos-to-update::after {
  content: '';
  display: block;
  width: calc(100% - 32px);
  height: 32px;
  left: 16px;
  bottom: 0;
  position: absolute;
  background-image: linear-gradient(to top, #fff, transparent);
}
.va-modal.modal-crud {
  .va-modal__title {
    line-height: 1.5;
    margin: 0 0 12px;
  }
  display: flex;
  justify-content: flex-end;
  .va-modal__dialog {
    height: 100vh;
    max-height: 100vh !important;
    border-radius: 0;
    margin: 0;
    max-width: 80vw !important;
    width: 80vw;
    .va-modal__inner {
      max-width: 100%;
      height: 100%;
    }
  }
  .va-input {
    display: block;
    width: 100%;
  }
  .va-input.no-border .va-input-wrapper__field {
    border-color: transparent !important;
  }
  .va-input.va-input-wrapper--readonly .va-input-wrapper__field,
  .va-select.va-input-wrapper--readonly .va-input-wrapper__field {
    opacity: 0.8;
  }
  .single-full-width {
    margin-left: 1%;
    margin-right: 3%;
    width: 96%;
  }
  .allow-pos {
    margin-top: 2rem;
  }
  .block-upload {
    .list-files {
      .file-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 0.5em;
        font-size: 14px;
        a {
          display: block;
          text-decoration: underline;
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .icon {
          margin-left: 1rem;
          font-size: 1rem;
          color: var(--va-danger);
          cursor: pointer;
        }
      }
    }
    .va-file-upload {
      background-color: transparent !important;
      margin: 0;
      width: fit-content;
      display: flex;
      align-items: center;
      .va-button--normal .va-button__content {
        font-size: 14px;
        white-space: nowrap;
      }
      .va-file-upload-single-item__content {
        justify-content: space-between
      }
      .va-file-upload-list {
        display: inline-block;
        margin: 0 0 0 1rem;
        padding: 0;
        width: fit-content;
        .va-file-upload-single-item__name {
          margin-right: 1rem;
        }
      }
    }
  }
  .va-input-wrapper:not(.no-resize) textarea {
    resize: vertical !important;
  }
  .va-input-wrapper.no-resize textarea {
    height: 20px;
    margin: 0;
    resize: none !important;
  }
  .va-button:disabled {
    opacity: 0.7;
  }
}
.va-modal.merchant-crud,
.va-modal.modal-pos-form {
  .va-modal__message {
    padding-top: 2rem;
    .modal-header-tabs {
      position: absolute;
      z-index: 99;
      top: 3rem;
      left: 0;
      background-color: var(--va-background-secondary);
      box-shadow: 0px 20px 20px 0 rgba(0, 0, 0, .03);
    }
    .add-merchant-form,
    .add-pos-form {
      margin-bottom: 4rem;
      .form-actions {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 99;
        background: var(--va-background-secondary);
        box-shadow: 0px -20px 20px 0 rgba(0, 0, 0, .03);
      }
    }
  }

  .merchant-bank-item {
    position: relative;
    .absolute-icon {
      position: absolute;
      right: 6px;
      bottom: 12px;
    }
  }
}

.va-modal.modal-pos-form .va-modal__message {
  padding-top: 0;
}
.va-modal.modal-crud.modal-filter .va-modal__dialog {
  width: 100%;
  max-width: 480px !important;
}

.va-list-item.export-item {
  font-size: 14px;
  line-height: 22px;
  transition: all 0.3s;
}

.va-list-item.export-item:hover {
  background: #f4f6f8;
  color: #f4823c;
}

.table-header .va-chip {
  display: block;
  height: 40px;
  line-height: 34px;
  cursor: pointer;
}

.chart-div.line-chart {
  position: relative;
  width: calc(100% - 396px);
}

.chart-div #line-chart,
.chart-div #pie-chart {
  transition: opacity 0.3s ease;
}

.chart-div.loading #line-chart,
.chart-div.loading #pie-chart {
  opacity: 0.4;
}

.chart-div .chart-header .va-select {
  max-width: 280px;
}

.table-header .va-input-wrapper__text input {
  line-height: 22px;
}

.va-modal.modal-crud.va-modal-enter-from .va-modal__dialog,
.va-modal.modal-crud.va-modal-leave-to .va-modal__dialog {
  opacity: 0;
  transform: translateX(30%)
}

.va-modal.modal-crud.va-modal-enter-active .va-modal__dialog,
.va-modal.modal-crud.va-modal-leave-active .va-modal__dialog {
  transition: opacity var(--va-modal-opacity-transition), transform var(--va-modal-transform-transition)
}

.va-modal--fixed-layout.modal-crud .va-modal__inner {
  max-height: 100vh;
}

.va-modal__dialog.va-modal--mobile-fullscreen {
  box-shadow: 0 0 20px;
}

.list_merchant_banks .mb-text-input .va-input-wrapper--readonly {
  opacity: 0.7;
}

.footer-paging {
  padding: 2rem 0 1rem;
}

.paging {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  .items-per-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .va-select {
      width: 80px;
      height: 36px;
      margin-left: 16px;
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 2rem;
    a {
      display: block;
      width: 36px;
      height: 36px;
      line-height: 34px;
      font-size: 24px;
      text-align: center;
      background: transparent;
      color: var(--va-text-primary);
      transition: all 0.3s;
      border: 1px solid #e4e6e7;
    }
    a:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    a:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    a:not(.disabled):hover {
      background: #d0dcf3;
    }
    a.page-index {
      font-size: 16px;
    }
    a.page-index.active {
      background: var(--va-primary);
      color: #f1f1f1;
      font-weight: 600;
    }
    a.disabled {
      cursor: default;
      color: #154ec17f;
    }
  }
}

.block-info {
  padding: 1rem 0.5rem 1.5rem 0.5rem;
  border: 1px solid var(--va-input-wrapper-border-color);
  border-radius: 12px;
  margin-top: 2rem;
  position: relative;
  .block-title {
    margin: 0;
    position: absolute;
    font-size: 1rem;
    line-height: 1;
    padding: 0 6px;
    top: -10px;
    left: 1rem;
    background: var(--va-background-secondary);
  }
}

.block-info:last-child {
  margin-bottom: 2rem;
}

.report-widget {
  transition: all 0.3s;
  .page-title {
    margin-bottom: 0;
  }
  
  .va-card__content {
    overflow-x: auto;
    .list-report-items {
      width: fit-content;
      .report-item {
        min-width: 280px;
      }
    }
  }
}

.report-widget.loading {
  opacity: 0.5;
}

.report-item {
  min-width: 280px;
  .va-card-title {
    background-color: var(--va-info);
    color: #fff;
    font-size: 14px;
    .va-chip--small .va-chip__content {
      color: #fff;
    }
  }

  .va-card__title + .va-card__content {
    padding: 12px 4px 0;
    // border: 1px solid var(--va-info);
  }
  
  p.title {
    border-bottom: 1px solid var(--va-input-wrapper-border-color);
  }

  span.percentage {
    font-size: 12px;
    opacity: 0.7;
    letter-spacing: -0.32px;
    margin-left: 2px;
  }

  .hide {
    opacity: 0.24;
    user-select: none;
    span.percentage {
      display: none;
    }
  }
}

.hide-desktop {
  display: none;
}

// .va-carousel__arrow {
//   display: none !important;
// }

.report-widget .va-carousel__arrow--left {
  left: 2px;
  border-radius: 100%;
  overflow: hidden;
}

.report-widget .va-carousel__arrow--right {
  right: 2px;
  border-radius: 100%;
  overflow: hidden;
}

@media screen and (max-width: $tabletBreakPointPX) {
  .show-desktop {
    display: none;
  }
  .hide-desktop {
    display: block;
  }
  .filter-block {
    .filter-form {
      .actions .va-button span {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        font-size: 14px;
      }
      .filter-items {
        justify-content: space-between;
        .filter-item {
          width: 48%;
          margin-right: 0;
        }
      }
    }
  }
}

.mobile-button {
  display: none;
}

@media screen and (max-width: $mobileBreakPointPX) {
  .page-header .page-title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 1rem;
  }

  .desktop-button {
    display: none !important;
  }

  .mobile-button {
    display: block;
  }

  .report-table-header .filter-main-input {
    width: 100%;
    order: 5;
  }

  .chart-div.line-chart {
    width: 100%;
  }

  .va-modal.modal-crud {
    .va-modal__close {
      top: 4.75rem;
    }

    .va-modal__dialog .va-modal__inner {
      padding-top: 4rem;
    }

    .va-modal__title {
      padding: 0;
    }

    .va-modal__message h2 {
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .va-modal__message .modal-header-tabs {
      top: 6rem;
    }

    .empty {
      display: none;
    }
  }

  .va-modal.modal-crud.merchant-crud {
    .va-modal__close {
      top: 4rem;
    }
  }

  .add-user-form .modal-footer,
  .modal-pos-form .va-modal__footer,
  .modal-filter .va-modal__footer {
    padding-bottom: 4rem;
  }

  .va-modal:not(.modal-crud) .va-modal__title {
    line-height: 1.625;
  }

  .filter-block {
    .filter-form {
      .filter-items {
        .filter-item {
          width: 100%;
        }
      }
      .actions {
        grid-row: auto;
        grid-column: 1;
      }
    }
  }

  .table-crud {
    .va-data-table__table,
    .va-data-table__table .va-data-table__table-th {
      font-size: 12px;
    }
  }

  .card-header {
    .va-card__title {
      font-size: 14px;
      padding: 1.25rem 0.75rem;
    }

    .va-card__content {
      padding: 0.75rem;
    }

    .actions a.btn {
      padding: 0.5rem 0.75rem;
      margin-right: 0.75rem;
      font-size: 13px;
    }

    .filter .search-box {
      .va-icon {
        top: 4px;
        right: 15px;
      }
      .va-icon.ion-ios-close {
        top: 1px;
        right: 18px;
      }
      .search {
        width: 32px;
        height: 32px;
        margin-right: 0.5rem;
      }
      .search.expand {
        width: 128px;
        padding-left: 12px;
        padding-right: 28px;
        font-size: 14px;
      }
    }
  }

  .user-crud {
    .va-modal__message,
    .va-modal__message .row-items {
      .modal-input-wrapper {
        max-width: 100%;
        margin-right: 0;
      }
      .va-switch.modal-input-wrapper,
      .va-file-upload--dropzone.modal-input-wrapper {
        .va-file-upload__field,
        .va-file-upload__field__input,
        .va-file-upload-list {
          width: 100%;
        }
      }
    }
    .va-modal__message .row-items .va-switch.modal-input-wrapper {
      max-width: 100%;
      margin-right: 0;
    }
    .va-modal__message .row-items .current-image {
      width: 100%;
    }
  }

  .va-modal.merchant-crud .va-modal__message .add-merchant-form .form-actions {
    padding-bottom: 4rem;
  }

  .va-modal.merchant-crud .va-modal__message .add-merchant-form {
    margin-bottom: 8rem;
  }

  .merchant-bank-item {
    padding-top: 1rem;
    border-top: 1px solid var(--va-input-wrapper-border-color);
  }

  .paging {
    width: 100%;
    justify-content: space-between;
    margin-top: 1rem;
    .items-per-page {
      font-size: 14px;
      .label {
        display: none;
      }
      .va-select {
        margin: 0 8px 0 0;
        width: 64px;
        .va-input-wrapper__field {
          padding: 8px 4px 8px 6px;
        }
      }
    }
    .pagination {
      margin: 0;
      a.page-index {
        font-size: 14px;
      }
    }
  }

  .report-item {
    padding: 16px !important;
    .va-card__title + .va-card__content {
      padding: 12px 16px 0;
    }
  }
}
</style>
